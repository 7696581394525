import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            main: "#0097a7",
            light: "#56c8d8",
            dark: "#006978",
        },
        secondary: {
            main: "#00c5da",
            light: "#64f8ff",
            dark: "#0094a8",
        },
        error: {
            main: red.A400,
        },
    },
    typography: {
        fontFamily: '"Montserrat", sans-serif',
        subtitle1: {
            fontFamily: '"Montserrat", sans-serif',
            marginTop: "2em",
        },
        h6: {
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "24pt",
        },
        subtitle2: {
            fontFamily: '"Montserrat", sans-serif',
            fontSize: "1rem",
            padding: "1em",
            marginBottom: "1em",
            letterSpacing: 3,
            position: "sticky",
            top: "62px",
            left: "-30px",
            width: "100%",
            color: "#0097a7",
            backgroundColor: "#ffffff",
            borderBottom: "1px solid #0097a7",
            // boxShadow: "0 3px 5px rgba(0, 0, 0, 0.1)",
            zIndex: 999,
        },
    },
});

export default theme;
