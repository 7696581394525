import * as React from 'react';
import Script from 'next/script'
import Router, { useRouter } from 'next/router'
import { SessionProvider } from "next-auth/react"
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import type { AppProps as NextAppProps } from "next/app";

// modified version - allows for custom pageProps type, falling back to 'any'
type AppProps<P = any> = {
  pageProps: P;
} & Omit<NextAppProps<P>, "pageProps">;

import Head from 'next/head';
import theme from '../src/theme';

import { useSession } from 'next-auth/react'
import { QueryClientProvider, QueryClient } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools'

import {
  RecoilRoot
} from 'recoil';
import { json } from 'stream/consumers';
import { useState } from 'react';

type WeaveAppProps = {
    session: any
}

export default function Weave({
    Component,
    pageProps,
  }: AppProps<WeaveAppProps>) {

  const queryClient = new QueryClient();

  const router = useRouter()

  const [showing, setShowing] = useState(false);

  React.useEffect(() => {
    setShowing(true);
  }, []);

  if (!showing) {
    return null;
  }

  return (
    <SessionProvider session={pageProps.session}>
      <QueryClientProvider client={queryClient} contextSharing={true}>
        <Head>
          <title>Weave</title>
          <meta content="minimum-scale=1, initial-scale=1, width=device-width" name="viewport" />
          <meta name="insight-app-sec-validation" content="c5e26786-0730-480d-880a-24e110711aa8"></meta>
          <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
          <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
          <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
          <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
          <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
          <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
          <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
          <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
          <link rel="icon" type="image/png" sizes="192x192"  href="/android-icon-192x192.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/manifest.json" />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
          <meta name="theme-color" content="#ffffff" />
        </Head>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RecoilRoot>
                <Component {...pageProps} />    
          </RecoilRoot>
        </ThemeProvider>
        {/* <ReactQueryDevtools /> */}
      </QueryClientProvider>
    </SessionProvider>
  );
}
